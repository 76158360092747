import { apiURL } from '@/main'
import { showError } from '@/services/axios'
import { defineStore } from 'pinia'
import axios from 'axios'

export const useCantonsStore = defineStore('CantonsStore', {
  state: () => ({
    Cantons: [] as any[],
    CantonsCH: [] as any[],
    CantonsIncVirtual: [] as any[]
  }),
  actions: {
    async fetchCantons() {
      if (this.Cantons.length === 0) {
        try {
          const result = await axios.get(apiURL + '/cantons')
          return (this.Cantons = result.data)
        } catch (e) {
          showError(e)
        }
      }
    },
    async fetchCantonsCH() {
      if (this.CantonsCH.length === 0) {
        try {
          const result = await axios.get(apiURL + '/cantons/CH')
          this.CantonsCH = result.data
        } catch (e) {
          showError(e)
        }
      }
    },
    async fetchCantonsIncVirtual() {
      if (this.CantonsIncVirtual.length === 0) {
        try {
          const result = await axios.get(apiURL + '/cantons?includeVirtual=true')
          this.CantonsIncVirtual = result.data
        } catch (e) {
          showError(e)
        }
      }
    }
  }
})
